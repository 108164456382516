/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartsProduct,
    PartsProductFromJSON,
    PartsProductFromJSONTyped,
    PartsProductToJSON,
    RecommendDesignItemsFamilycrestColor,
    RecommendDesignItemsFamilycrestColorFromJSON,
    RecommendDesignItemsFamilycrestColorFromJSONTyped,
    RecommendDesignItemsFamilycrestColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecommendDesignItems
 */
export interface RecommendDesignItems {
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    shaft: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    feather: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    thread: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    kebiki: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    nock: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    arrowhead: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    arrowlength: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    unit: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    arrowinsert: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    twine: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    lame: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    carving: PartsProduct | null;
    /**
     * 刻印する文字
     * @type {string}
     * @memberof RecommendDesignItems
     */
    carvingString?: string;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    familycrest: PartsProduct | null;
    /**
     * 
     * @type {RecommendDesignItemsFamilycrestColor}
     * @memberof RecommendDesignItems
     */
    familycrestColor: RecommendDesignItemsFamilycrestColor | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    featherinside: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    decoration: PartsProduct | null;
    /**
     * 
     * @type {PartsProduct}
     * @memberof RecommendDesignItems
     */
    typezero: PartsProduct | null;
    /**
     * 糸・和紙のツヤ。true=ツヤあり、false=ツヤなし
     * @type {boolean}
     * @memberof RecommendDesignItems
     */
    threadgloss: boolean;
    /**
     * 羽根無し時の糸・和紙の場所 * `uraari` - 末矧あり * `uranashi` - 末矧なし
     * @type {string}
     * @memberof RecommendDesignItems
     */
    threadPosition: RecommendDesignItemsThreadPositionEnum;
}

/**
* @export
* @enum {string}
*/
export enum RecommendDesignItemsThreadPositionEnum {
    Uraari = 'uraari',
    Uranashi = 'uranashi'
}

export function RecommendDesignItemsFromJSON(json: any): RecommendDesignItems {
    return RecommendDesignItemsFromJSONTyped(json, false);
}

export function RecommendDesignItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendDesignItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shaft': PartsProductFromJSON(json['shaft']),
        'feather': PartsProductFromJSON(json['feather']),
        'thread': PartsProductFromJSON(json['thread']),
        'kebiki': PartsProductFromJSON(json['kebiki']),
        'nock': PartsProductFromJSON(json['nock']),
        'arrowhead': PartsProductFromJSON(json['arrowhead']),
        'arrowlength': PartsProductFromJSON(json['arrowlength']),
        'unit': PartsProductFromJSON(json['unit']),
        'arrowinsert': PartsProductFromJSON(json['arrowinsert']),
        'twine': PartsProductFromJSON(json['twine']),
        'lame': PartsProductFromJSON(json['lame']),
        'carving': PartsProductFromJSON(json['carving']),
        'carvingString': !exists(json, 'carvingString') ? undefined : json['carvingString'],
        'familycrest': PartsProductFromJSON(json['familycrest']),
        'familycrestColor': RecommendDesignItemsFamilycrestColorFromJSON(json['familycrestColor']),
        'featherinside': PartsProductFromJSON(json['featherinside']),
        'decoration': PartsProductFromJSON(json['decoration']),
        'typezero': PartsProductFromJSON(json['typezero']),
        'threadgloss': json['threadgloss'],
        'threadPosition': json['thread_position'],
    };
}

export function RecommendDesignItemsToJSON(value?: RecommendDesignItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shaft': PartsProductToJSON(value.shaft),
        'feather': PartsProductToJSON(value.feather),
        'thread': PartsProductToJSON(value.thread),
        'kebiki': PartsProductToJSON(value.kebiki),
        'nock': PartsProductToJSON(value.nock),
        'arrowhead': PartsProductToJSON(value.arrowhead),
        'arrowlength': PartsProductToJSON(value.arrowlength),
        'unit': PartsProductToJSON(value.unit),
        'arrowinsert': PartsProductToJSON(value.arrowinsert),
        'twine': PartsProductToJSON(value.twine),
        'lame': PartsProductToJSON(value.lame),
        'carving': PartsProductToJSON(value.carving),
        'carvingString': value.carvingString,
        'familycrest': PartsProductToJSON(value.familycrest),
        'familycrestColor': RecommendDesignItemsFamilycrestColorToJSON(value.familycrestColor),
        'featherinside': PartsProductToJSON(value.featherinside),
        'decoration': PartsProductToJSON(value.decoration),
        'typezero': PartsProductToJSON(value.typezero),
        'threadgloss': value.threadgloss,
        'thread_position': value.threadPosition,
    };
}


