/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetResponseDefaultUnitUnitData
 */
export interface GetResponseDefaultUnitUnitData {
    /**
     * 
     * @type {number}
     * @memberof GetResponseDefaultUnitUnitData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetResponseDefaultUnitUnitData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetResponseDefaultUnitUnitData
     */
    num?: number;
}

export function GetResponseDefaultUnitUnitDataFromJSON(json: any): GetResponseDefaultUnitUnitData {
    return GetResponseDefaultUnitUnitDataFromJSONTyped(json, false);
}

export function GetResponseDefaultUnitUnitDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponseDefaultUnitUnitData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'num': !exists(json, 'num') ? undefined : json['num'],
    };
}

export function GetResponseDefaultUnitUnitDataToJSON(value?: GetResponseDefaultUnitUnitData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'num': value.num,
    };
}


