import { PartsProduct } from '@app/react/const/type';
import { Apply, Apply2, ApplyMultiple } from '@app/react/3d/texHandler';
import { texLoader } from '@app/react/common/texLoader';
import { range } from '@app/react/common/range';
import { isFeatherNone } from '@app/react/common/isFeatherNone';
import { Texture } from 'three';
import { FamilycrestColorTextureData } from '@root/app/react/saga/threejsTask/common';

export const fetchAndApply = async (parts: PartsProduct | null, cb: Apply): Promise<void> => {
    const [texImg] = parts?.textureImages ?? [];
    if (texImg === undefined) {
        return;
    }

    const t = await texLoader.loadAsync(texImg);
    cb(t, parts?.options);
};

export const fetchAndApply2 = async (parts: PartsProduct | null, cb: Apply2): Promise<void> => {
    const [texImg1, texImg2] = parts?.textureImages ?? [];
    if (texImg1 === undefined || texImg2 === undefined) {
        return;
    }

    const t1 = texLoader.loadAsync(texImg1);
    const t2 = texLoader.loadAsync(texImg2);
    const [tex1, tex2] = await Promise.all([t1, t2]);
    cb(tex1, tex2, parts?.options);
};

export const fetchAndApplyMultiple = async (
    parts: PartsProduct | null,
    partsName: string,
    cb: ApplyMultiple,
): Promise<void> => {
    if (parts === null) {
        console.log(`skip apply texture. ${partsName} not found`);
        return Promise.resolve();
    }

    const { length } = parts.textureImages;
    if (length === 0) {
        console.error(`${partsName} texture not found`);
        return Promise.resolve();
    }

    const tasks = range(0, length - 1)
        .map((index) => texLoader.loadAsync(parts.textureImages[index]));

    const result = await Promise.all(tasks);
    result.forEach((tex: Texture, index: number) => {
        tex.name = `${partsName}.${parts.name}.${index}`;
    });

    cb(result, parts?.options);
    return Promise.resolve();
};

export const fetchAndApplyWithMakiwara = (
    parts: PartsProduct | null,
    partsName: string,
    cb: Apply2,
    reset: () => void,
): Promise<void> => {
    if (parts === null) {
        console.log(`skip apply texture. ${partsName} not found`);
        return Promise.resolve();
    }

    if (isFeatherNone(parts)) {
        reset();
        return Promise.resolve();
    }

    return fetchAndApply2(parts, cb);
};

export const fetchAndApplyFamilycrestColor = async (
    parts: FamilycrestColorTextureData | null,
    cb: Apply,
): Promise<void> => {
    const texImg = parts?.textureImage;
    if (texImg === undefined) {
        return Promise.resolve();
    }

    const t = await texLoader.loadAsync(texImg);
    cb(t);
    return Promise.resolve();
};
