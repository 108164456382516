/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArrowType,
    ArrowTypeFromJSON,
    ArrowTypeFromJSONTyped,
    ArrowTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200ArrowTypes
 */
export interface InlineResponse200ArrowTypes {
    /**
     * 
     * @type {ArrowType}
     * @memberof InlineResponse200ArrowTypes
     */
    code?: ArrowType;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200ArrowTypes
     */
    name?: string;
}

export function InlineResponse200ArrowTypesFromJSON(json: any): InlineResponse200ArrowTypes {
    return InlineResponse200ArrowTypesFromJSONTyped(json, false);
}

export function InlineResponse200ArrowTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200ArrowTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : ArrowTypeFromJSON(json['code']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function InlineResponse200ArrowTypesToJSON(value?: InlineResponse200ArrowTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': ArrowTypeToJSON(value.code),
        'name': value.name,
    };
}


