/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddCart,
    AddCartFromJSON,
    AddCartToJSON,
    ArrowType,
    ArrowTypeFromJSON,
    ArrowTypeToJSON,
    GetResponseCartItemData,
    GetResponseCartItemDataFromJSON,
    GetResponseCartItemDataToJSON,
    GetResponseDefaultUnit,
    GetResponseDefaultUnitFromJSON,
    GetResponseDefaultUnitToJSON,
    GetResponseFamilyCrestColors,
    GetResponseFamilyCrestColorsFromJSON,
    GetResponseFamilyCrestColorsToJSON,
    GetResponseOrderParts,
    GetResponseOrderPartsFromJSON,
    GetResponseOrderPartsToJSON,
    GetResponseRecommendDesigns,
    GetResponseRecommendDesignsFromJSON,
    GetResponseRecommendDesignsToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    OrderType,
    OrderTypeFromJSON,
    OrderTypeToJSON,
    OrdermadeArrowPartsName,
    OrdermadeArrowPartsNameFromJSON,
    OrdermadeArrowPartsNameToJSON,
    SelectedItems,
    SelectedItemsFromJSON,
    SelectedItemsToJSON,
} from '../models';

export interface OrderMadeFunctionAddCartPostRequest {
    eCCUBECSRFTOKEN: string;
    addCart?: AddCart;
}

export interface OrderMadeFunctionEditCartPostRequest {
    eCCUBECSRFTOKEN: string;
    inlineObject?: InlineObject;
}

export interface OrderMadeFunctionGetCartitemDataGetRequest {
    cartitemId: number;
}

export interface OrderMadeFunctionGetDefaultUnitGetRequest {
    arrowType: ArrowType;
}

export interface OrderMadeFunctionGetFamilycrestColorsGetRequest {
    featherId: number;
}

export interface OrderMadeFunctionGetOrderPartsGetRequest {
    kind: OrdermadeArrowPartsName;
    arrowType: ArrowType;
    orderType: OrderType;
    selectedItems: SelectedItems;
}

export interface OrderMadeFunctionGetRecommendDesignsGetRequest {
    arrowType: ArrowType;
    orderType: OrderType;
}

export interface OrderMadeFunctionGetSelectableItemsGetRequest {
    arrowType: ArrowType;
    orderType: OrderType;
}

export interface OrderMadeFunctionRecommendDesignsRidGetRequest {
    rid: number;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * オーダメイドパーツをカートに追加
     */
    async orderMadeFunctionAddCartPostRaw(requestParameters: OrderMadeFunctionAddCartPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eCCUBECSRFTOKEN === null || requestParameters.eCCUBECSRFTOKEN === undefined) {
            throw new runtime.RequiredError('eCCUBECSRFTOKEN','Required parameter requestParameters.eCCUBECSRFTOKEN was null or undefined when calling orderMadeFunctionAddCartPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.eCCUBECSRFTOKEN !== undefined && requestParameters.eCCUBECSRFTOKEN !== null) {
            headerParameters['ECCUBE-CSRF-TOKEN'] = String(requestParameters.eCCUBECSRFTOKEN);
        }

        const response = await this.request({
            path: `/order_made/function/add_cart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCartToJSON(requestParameters.addCart),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * オーダメイドパーツをカートに追加
     */
    async orderMadeFunctionAddCartPost(requestParameters: OrderMadeFunctionAddCartPostRequest): Promise<void> {
        await this.orderMadeFunctionAddCartPostRaw(requestParameters);
    }

    /**
     * カート内のオーダメイドパーツを変更する
     */
    async orderMadeFunctionEditCartPostRaw(requestParameters: OrderMadeFunctionEditCartPostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eCCUBECSRFTOKEN === null || requestParameters.eCCUBECSRFTOKEN === undefined) {
            throw new runtime.RequiredError('eCCUBECSRFTOKEN','Required parameter requestParameters.eCCUBECSRFTOKEN was null or undefined when calling orderMadeFunctionEditCartPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.eCCUBECSRFTOKEN !== undefined && requestParameters.eCCUBECSRFTOKEN !== null) {
            headerParameters['ECCUBE-CSRF-TOKEN'] = String(requestParameters.eCCUBECSRFTOKEN);
        }

        const response = await this.request({
            path: `/order_made/function/edit_cart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * カート内のオーダメイドパーツを変更する
     */
    async orderMadeFunctionEditCartPost(requestParameters: OrderMadeFunctionEditCartPostRequest): Promise<void> {
        await this.orderMadeFunctionEditCartPostRaw(requestParameters);
    }

    /**
     * 矢の種別を取得する
     */
    async orderMadeFunctionGetArrowTypesGetRaw(): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_arrow_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * 矢の種別を取得する
     */
    async orderMadeFunctionGetArrowTypesGet(): Promise<InlineResponse200> {
        const response = await this.orderMadeFunctionGetArrowTypesGetRaw();
        return await response.value();
    }

    /**
     * カート追加後の変更時にオーダーパーツ情報を取得する
     */
    async orderMadeFunctionGetCartitemDataGetRaw(requestParameters: OrderMadeFunctionGetCartitemDataGetRequest): Promise<runtime.ApiResponse<GetResponseCartItemData>> {
        if (requestParameters.cartitemId === null || requestParameters.cartitemId === undefined) {
            throw new runtime.RequiredError('cartitemId','Required parameter requestParameters.cartitemId was null or undefined when calling orderMadeFunctionGetCartitemDataGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.cartitemId !== undefined) {
            queryParameters['cartitem_id'] = requestParameters.cartitemId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_cartitem_data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseCartItemDataFromJSON(jsonValue));
    }

    /**
     * カート追加後の変更時にオーダーパーツ情報を取得する
     */
    async orderMadeFunctionGetCartitemDataGet(requestParameters: OrderMadeFunctionGetCartitemDataGetRequest): Promise<GetResponseCartItemData> {
        const response = await this.orderMadeFunctionGetCartitemDataGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * デフォルトの矢の本数を取得する
     */
    async orderMadeFunctionGetDefaultUnitGetRaw(requestParameters: OrderMadeFunctionGetDefaultUnitGetRequest): Promise<runtime.ApiResponse<GetResponseDefaultUnit>> {
        if (requestParameters.arrowType === null || requestParameters.arrowType === undefined) {
            throw new runtime.RequiredError('arrowType','Required parameter requestParameters.arrowType was null or undefined when calling orderMadeFunctionGetDefaultUnitGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.arrowType !== undefined) {
            queryParameters['arrow_type'] = requestParameters.arrowType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_default_unit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseDefaultUnitFromJSON(jsonValue));
    }

    /**
     * デフォルトの矢の本数を取得する
     */
    async orderMadeFunctionGetDefaultUnitGet(requestParameters: OrderMadeFunctionGetDefaultUnitGetRequest): Promise<GetResponseDefaultUnit> {
        const response = await this.orderMadeFunctionGetDefaultUnitGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * 家紋選択画面で使用できる色のデータを取得する
     */
    async orderMadeFunctionGetFamilycrestColorsGetRaw(requestParameters: OrderMadeFunctionGetFamilycrestColorsGetRequest): Promise<runtime.ApiResponse<GetResponseFamilyCrestColors>> {
        if (requestParameters.featherId === null || requestParameters.featherId === undefined) {
            throw new runtime.RequiredError('featherId','Required parameter requestParameters.featherId was null or undefined when calling orderMadeFunctionGetFamilycrestColorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.featherId !== undefined) {
            queryParameters['feather_id'] = requestParameters.featherId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_familycrest_colors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseFamilyCrestColorsFromJSON(jsonValue));
    }

    /**
     * 家紋選択画面で使用できる色のデータを取得する
     */
    async orderMadeFunctionGetFamilycrestColorsGet(requestParameters: OrderMadeFunctionGetFamilycrestColorsGetRequest): Promise<GetResponseFamilyCrestColors> {
        const response = await this.orderMadeFunctionGetFamilycrestColorsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * オーダメイドパーツを取得
     */
    async orderMadeFunctionGetOrderPartsGetRaw(requestParameters: OrderMadeFunctionGetOrderPartsGetRequest): Promise<runtime.ApiResponse<GetResponseOrderParts>> {
        if (requestParameters.kind === null || requestParameters.kind === undefined) {
            throw new runtime.RequiredError('kind','Required parameter requestParameters.kind was null or undefined when calling orderMadeFunctionGetOrderPartsGet.');
        }

        if (requestParameters.arrowType === null || requestParameters.arrowType === undefined) {
            throw new runtime.RequiredError('arrowType','Required parameter requestParameters.arrowType was null or undefined when calling orderMadeFunctionGetOrderPartsGet.');
        }

        if (requestParameters.orderType === null || requestParameters.orderType === undefined) {
            throw new runtime.RequiredError('orderType','Required parameter requestParameters.orderType was null or undefined when calling orderMadeFunctionGetOrderPartsGet.');
        }

        if (requestParameters.selectedItems === null || requestParameters.selectedItems === undefined) {
            throw new runtime.RequiredError('selectedItems','Required parameter requestParameters.selectedItems was null or undefined when calling orderMadeFunctionGetOrderPartsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.kind !== undefined) {
            queryParameters['kind'] = requestParameters.kind;
        }

        if (requestParameters.arrowType !== undefined) {
            queryParameters['arrow_type'] = requestParameters.arrowType;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['order_type'] = requestParameters.orderType;
        }

        if (requestParameters.selectedItems !== undefined) {
            queryParameters['selected_items'] = requestParameters.selectedItems;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_order_parts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseOrderPartsFromJSON(jsonValue));
    }

    /**
     * オーダメイドパーツを取得
     */
    async orderMadeFunctionGetOrderPartsGet(requestParameters: OrderMadeFunctionGetOrderPartsGetRequest): Promise<GetResponseOrderParts> {
        const response = await this.orderMadeFunctionGetOrderPartsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * オーダーの種別を取得する
     */
    async orderMadeFunctionGetOrderTypesGetRaw(): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_order_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * オーダーの種別を取得する
     */
    async orderMadeFunctionGetOrderTypesGet(): Promise<InlineResponse2001> {
        const response = await this.orderMadeFunctionGetOrderTypesGetRaw();
        return await response.value();
    }

    /**
     * おすすめデザインのデータを取得する
     */
    async orderMadeFunctionGetRecommendDesignsGetRaw(requestParameters: OrderMadeFunctionGetRecommendDesignsGetRequest): Promise<runtime.ApiResponse<GetResponseRecommendDesigns>> {
        if (requestParameters.arrowType === null || requestParameters.arrowType === undefined) {
            throw new runtime.RequiredError('arrowType','Required parameter requestParameters.arrowType was null or undefined when calling orderMadeFunctionGetRecommendDesignsGet.');
        }

        if (requestParameters.orderType === null || requestParameters.orderType === undefined) {
            throw new runtime.RequiredError('orderType','Required parameter requestParameters.orderType was null or undefined when calling orderMadeFunctionGetRecommendDesignsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.arrowType !== undefined) {
            queryParameters['arrow_type'] = requestParameters.arrowType;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['order_type'] = requestParameters.orderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_recommend_designs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseRecommendDesignsFromJSON(jsonValue));
    }

    /**
     * おすすめデザインのデータを取得する
     */
    async orderMadeFunctionGetRecommendDesignsGet(requestParameters: OrderMadeFunctionGetRecommendDesignsGetRequest): Promise<GetResponseRecommendDesigns> {
        const response = await this.orderMadeFunctionGetRecommendDesignsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * 矢の種別とオーダーの種別から、設定できるパーツ種別を取得する
     */
    async orderMadeFunctionGetSelectableItemsGetRaw(requestParameters: OrderMadeFunctionGetSelectableItemsGetRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        if (requestParameters.arrowType === null || requestParameters.arrowType === undefined) {
            throw new runtime.RequiredError('arrowType','Required parameter requestParameters.arrowType was null or undefined when calling orderMadeFunctionGetSelectableItemsGet.');
        }

        if (requestParameters.orderType === null || requestParameters.orderType === undefined) {
            throw new runtime.RequiredError('orderType','Required parameter requestParameters.orderType was null or undefined when calling orderMadeFunctionGetSelectableItemsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.arrowType !== undefined) {
            queryParameters['arrow_type'] = requestParameters.arrowType;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['order_type'] = requestParameters.orderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/get_selectable_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * 矢の種別とオーダーの種別から、設定できるパーツ種別を取得する
     */
    async orderMadeFunctionGetSelectableItemsGet(requestParameters: OrderMadeFunctionGetSelectableItemsGetRequest): Promise<InlineResponse2002> {
        const response = await this.orderMadeFunctionGetSelectableItemsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * おすすめデザインのデータを取得する
     */
    async orderMadeFunctionRecommendDesignsRidGetRaw(requestParameters: OrderMadeFunctionRecommendDesignsRidGetRequest): Promise<runtime.ApiResponse<GetResponseCartItemData>> {
        if (requestParameters.rid === null || requestParameters.rid === undefined) {
            throw new runtime.RequiredError('rid','Required parameter requestParameters.rid was null or undefined when calling orderMadeFunctionRecommendDesignsRidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order_made/function/recommend_designs/{rid}`.replace(`{${"rid"}}`, encodeURIComponent(String(requestParameters.rid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponseCartItemDataFromJSON(jsonValue));
    }

    /**
     * おすすめデザインのデータを取得する
     */
    async orderMadeFunctionRecommendDesignsRidGet(requestParameters: OrderMadeFunctionRecommendDesignsRidGetRequest): Promise<GetResponseCartItemData> {
        const response = await this.orderMadeFunctionRecommendDesignsRidGetRaw(requestParameters);
        return await response.value();
    }

}
