/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderType,
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2001OrderTypes
 */
export interface InlineResponse2001OrderTypes {
    /**
     * 
     * @type {OrderType}
     * @memberof InlineResponse2001OrderTypes
     */
    code?: OrderType;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001OrderTypes
     */
    name?: string;
}

export function InlineResponse2001OrderTypesFromJSON(json: any): InlineResponse2001OrderTypes {
    return InlineResponse2001OrderTypesFromJSONTyped(json, false);
}

export function InlineResponse2001OrderTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2001OrderTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : OrderTypeFromJSON(json['code']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function InlineResponse2001OrderTypesToJSON(value?: InlineResponse2001OrderTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': OrderTypeToJSON(value.code),
        'name': value.name,
    };
}


