/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartsProductOptions,
    PartsProductOptionsFromJSON,
    PartsProductOptionsFromJSONTyped,
    PartsProductOptionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PartsProduct
 */
export interface PartsProduct {
    /**
     * 
     * @type {number}
     * @memberof PartsProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PartsProduct
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PartsProduct
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof PartsProduct
     */
    categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof PartsProduct
     */
    categoryName: string;
    /**
     * 
     * @type {number}
     * @memberof PartsProduct
     */
    subCategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof PartsProduct
     */
    subCategoryName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartsProduct
     */
    productImages: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartsProduct
     */
    textureImages: Array<string>;
    /**
     * 
     * @type {PartsProductOptions}
     * @memberof PartsProduct
     */
    options: PartsProductOptions;
}

export function PartsProductFromJSON(json: any): PartsProduct {
    return PartsProductFromJSONTyped(json, false);
}

export function PartsProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'price': json['price'],
        'categoryId': json['category_id'],
        'categoryName': json['category_name'],
        'subCategoryId': !exists(json, 'sub_category_id') ? undefined : json['sub_category_id'],
        'subCategoryName': !exists(json, 'sub_category_name') ? undefined : json['sub_category_name'],
        'productImages': json['product_images'],
        'textureImages': json['texture_images'],
        'options': PartsProductOptionsFromJSON(json['options']),
    };
}

export function PartsProductToJSON(value?: PartsProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'price': value.price,
        'category_id': value.categoryId,
        'category_name': value.categoryName,
        'sub_category_id': value.subCategoryId,
        'sub_category_name': value.subCategoryName,
        'product_images': value.productImages,
        'texture_images': value.textureImages,
        'options': PartsProductOptionsToJSON(value.options),
    };
}


