import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
    Scene,
    PerspectiveCamera,
    Group,
} from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { ASSETS_PATH } from '@app/react/const/variables';
import { createRenderer } from '@app/react/3d/utils/thumbnail';
import { createLight } from '@app/react/3d/utils/order3d';

const toRad = (degree: number) => degree * (Math.PI / 180);

const modelDesktopPositions = {
    init: {
        pos: { x: 8, y: -13, z: 12 },
        rot: { x: 0, y: 0, z: toRad(120) },
    },
    order: {
        pos: { x: -8, y: -14.5, z: 12 },
        rot: { x: 0, y: 0, z: toRad(65) },
    },
    aboutus: {
        pos: { x: 2.5, y: -14.5, z: 3 },
        rot: { x: 0, y: 0, z: toRad(90) },
    },
    shop: {
        pos: { x: 15, y: 0, z: 12 },
        rot: { x: 0, y: 0, z: toRad(180) },
    },
    fin: {
        pos: { x: -10.5, y: -11, z: 11 },
        rot: { x: 0, y: 0, z: toRad(45) },
   },
};

const modelMobilePositions = {
    init: {
        pos: { x: 5.5, y: -14.5, z: 11 },
        rot: { x: 0, y: 0, z: toRad(110) },
    },
    order: {
        pos: { x: -7, y: -14.5, z: 12 },
        rot: { x: 0, y: 0, z: toRad(65) },
    },
    aboutus: {
        pos: { x: 1, y: -15.5, z: 6 },
        rot: { x: 0, y: 0, z: toRad(90) },
    },
    shop: {
        pos: { x: 15, y: 1.2, z: -10 },
        rot: { x: 0, y: 0, z: toRad(180) },
    },
    fin: {
        pos: { x: -7.5, y: -13, z: 11 },
        rot: { x: 0, y: 0, z: toRad(61) },
    },
};

const breakpoints = {
    sm: 767,
    md: 768,
};

const md = `(min-width: ${breakpoints.md}px)`;
const sm = `(max-width: ${breakpoints.sm}px)`;

gsap.registerPlugin(ScrollTrigger);

const markers = false;

type SA = {
    model: Group,
    modelPositions: typeof modelDesktopPositions | typeof modelMobilePositions,
    zoomStart: string,
    shopStart: string,
    finStart: string,
};

const setupAnimation = ({
    model,
    modelPositions,
    zoomStart,
    shopStart,
    finStart,
}: SA) => {
    const { pos, rot } = modelPositions.init;
    model.position.set(pos.x, pos.y, pos.z);
    model.rotation.set(rot.x, rot.y, rot.z);

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-hero',
            start: 'top top',
            endTrigger: '#hero-contents',
            end: 'bottom top',
            scrub: true,
            markers,
        },
    });

    const heroTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-hero',
            start: 'top top',
            endTrigger: '#hero-contents',
            end: 'bottom top',
            scrub: true,
            markers,
        },
    });

    const aboutusTl = gsap.timeline({
        scrollTrigger: {
            trigger: '#hero-contents',
            start: 'bottom top',
            endTrigger: '#fullorder-contents',
            end: 'bottom top',
            scrub: true,
            markers,
        },
    });

    const zoomTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-aboutus',
            start: zoomStart,
            end: '+=100px',
            scrub: true,
            markers,
            pin: true,
            pinSpacing: true,
            anticipatePin: 1,
        },
    });

    const shopTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-aboutus',
            start: shopStart,
            endTrigger: '#aboutus-contents',
            end: 'bottom top',
            scrub: true,
            markers,
        },
    });

    const finTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.section-shop',
            start: finStart,
            endTrigger: '#shop-contents',
            end: 'bottom top',
            scrub: true,
            markers,
        },
    });

    tl.to('.diagonal-background', {
        x: '-100vw',
    });

    heroTl
        .to(model.position, {
            ...modelPositions.order.pos,
        }, 'label0')
        .to(model.rotation, {
            ...modelPositions.order.rot,
        }, 'label0');

    aboutusTl
        .to(model.position, {
            ...modelPositions.aboutus.pos,
        }, 'label1')
        .to(model.rotation, {
            ...modelPositions.aboutus.rot,
        }, 'label1')
        .to('.theme-background-overlay', {
            opacity: 0,
        }, 'label1');

    zoomTl
        .to('.theme-background', {
            mixBlendMode: 'normal',
            scaleX: 1.2,
            scaleY: 1.2,
        }, 'label21')
        .to('.theme-background-blue', {
            visibility: 'visible',
        }, 'label22');

    shopTl
        .to(model.position, {
            ...modelPositions.shop.pos,
        }, 'label2')
        .to(model.rotation, {
            ...modelPositions.shop.rot,
        }, 'label2')
        .to('.theme-background', {
            opacity: 0,
        }, 'label2');

    finTl
        .to(model.position, {
            ...modelPositions.fin.pos,
        }, 'label3')
        .to(model.rotation, {
            ...modelPositions.fin.rot,
        }, 'label3')
        .to('.theme-background-blue', {
            opacity: 0,
        }, 'label3');
};

export const initThreejs = (): void => {
    const canvas = document.getElementById('arrow-canvas') as HTMLCanvasElement;
    if (canvas === null) {
        console.error('[3d] canvas null');
        return;
    }

    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    console.log('[3d] renderer size:', width, height);

    const scene = new Scene();

    const { hemisphereLight, ambientLight, directionalLights } = createLight();
    [hemisphereLight, ambientLight, ...directionalLights].forEach((l) => scene.add(l));

    const renderer = createRenderer(canvas, width, height, window.devicePixelRatio);
    renderer.shadowMap.enabled = true;

    const camera = new PerspectiveCamera(30, width / height, 1, 2000);
    camera.position.set(0, 0, 30);

    const loader = new FBXLoader();

    loader.load(`${ASSETS_PATH}/models/kinteki.fbx`,
                (model) => {
                    model.name = 'YUMI';
                    console.log('[3d] load model', model);

                    ScrollTrigger.matchMedia({
                        [md]: () => {
                            setupAnimation({
                                model,
                                modelPositions: modelDesktopPositions,
                                zoomStart: 'top top+=30%',
                                shopStart: 'top top+=28%',
                                finStart: 'top top+=30%',
                            });
                        },
                        [sm]: () => {
                            setupAnimation({
                                model,
                                modelPositions: modelMobilePositions,
                                zoomStart: 'top top+=20%',
                                shopStart: 'top top+=15%',
                                finStart: 'top top+=20%',
                            });
                        },
                    });

                    scene.add(model);

                    // TODO: hide loading view
                });

    const onWindowResize = (): void => {
        const { innerWidth, innerHeight } = window;
        renderer.setSize(innerWidth, innerHeight);
        camera.aspect = innerWidth / innerHeight;
        camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', onWindowResize);
    window.addEventListener('unload', () => {
        window.removeEventListener('resize', onWindowResize);
    });

    function tick() {
        // FPS 30
        setTimeout(() => {
            requestAnimationFrame(tick);
        }, 1000 / 30);

        renderer.render(scene, camera);
    }

    tick();
};

initThreejs();
