/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArrowType,
    ArrowTypeFromJSON,
    ArrowTypeFromJSONTyped,
    ArrowTypeToJSON,
    OrderType,
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
    SelectedItems,
    SelectedItemsFromJSON,
    SelectedItemsFromJSONTyped,
    SelectedItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddCart
 */
export interface AddCart {
    /**
     * 
     * @type {ArrowType}
     * @memberof AddCart
     */
    arrowType: ArrowType;
    /**
     * 
     * @type {OrderType}
     * @memberof AddCart
     */
    orderType: OrderType;
    /**
     * 
     * @type {SelectedItems}
     * @memberof AddCart
     */
    selectedItems: SelectedItems;
}

export function AddCartFromJSON(json: any): AddCart {
    return AddCartFromJSONTyped(json, false);
}

export function AddCartFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arrowType': ArrowTypeFromJSON(json['arrow_type']),
        'orderType': OrderTypeFromJSON(json['order_type']),
        'selectedItems': SelectedItemsFromJSON(json['selected_items']),
    };
}

export function AddCartToJSON(value?: AddCart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arrow_type': ArrowTypeToJSON(value.arrowType),
        'order_type': OrderTypeToJSON(value.orderType),
        'selected_items': SelectedItemsToJSON(value.selectedItems),
    };
}


