/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrdermadeArrowPartsName,
    OrdermadeArrowPartsNameFromJSON,
    OrdermadeArrowPartsNameFromJSONTyped,
    OrdermadeArrowPartsNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2002SelectableItem
 */
export interface InlineResponse2002SelectableItem {
    /**
     * 
     * @type {OrdermadeArrowPartsName}
     * @memberof InlineResponse2002SelectableItem
     */
    code?: OrdermadeArrowPartsName;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002SelectableItem
     */
    name?: string;
}

export function InlineResponse2002SelectableItemFromJSON(json: any): InlineResponse2002SelectableItem {
    return InlineResponse2002SelectableItemFromJSONTyped(json, false);
}

export function InlineResponse2002SelectableItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2002SelectableItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : OrdermadeArrowPartsNameFromJSON(json['code']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function InlineResponse2002SelectableItemToJSON(value?: InlineResponse2002SelectableItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': OrdermadeArrowPartsNameToJSON(value.code),
        'name': value.name,
    };
}


