/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * フルオーダー・特急オーダー:
 *   * `full` - フルオーダー
 *   * `semi` - 特急オーダー
 * @export
 * @enum {string}
 */
export enum OrderType {
    Full = 'full',
    Semi = 'semi'
}

export function OrderTypeFromJSON(json: any): OrderType {
    return OrderTypeFromJSONTyped(json, false);
}

export function OrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderType {
    return json as OrderType;
}

export function OrderTypeToJSON(value?: OrderType | null): any {
    return value as any;
}

