/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 取得するパーツの種類:
 *   * `shaft` - シャフト
 *   * `feather` - 羽根
 *   * `thread` - 糸・和紙
 *   * `kebiki` - 毛引き
 *   * `nock` - 矢筈
 *   * `arrowhead` - 矢尻
 *   * `arrowlength` - 矢尺
 *   * `unit` - 本数
 *   * `arrowinsert` - インサート
 *   * `twine` - 筈巻
 *   * `lame` - ラメ
 *   * `carving` - 文字刻印
 *   * `familycrest` - 家紋
 *   * `featherinside` - 羽中文字
 *   * `decoration` - プチデコレーション
 *   * `typezero` - ZERO流
 * @export
 * @enum {string}
 */
export enum OrdermadeArrowPartsName {
    Shaft = 'shaft',
    Feather = 'feather',
    Thread = 'thread',
    Kebiki = 'kebiki',
    Nock = 'nock',
    Arrowhead = 'arrowhead',
    Arrowlength = 'arrowlength',
    Unit = 'unit',
    Arrowinsert = 'arrowinsert',
    Twine = 'twine',
    Lame = 'lame',
    Carving = 'carving',
    Familycrest = 'familycrest',
    Featherinside = 'featherinside',
    Decoration = 'decoration',
    Typezero = 'typezero'
}

export function OrdermadeArrowPartsNameFromJSON(json: any): OrdermadeArrowPartsName {
    return OrdermadeArrowPartsNameFromJSONTyped(json, false);
}

export function OrdermadeArrowPartsNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrdermadeArrowPartsName {
    return json as OrdermadeArrowPartsName;
}

export function OrdermadeArrowPartsNameToJSON(value?: OrdermadeArrowPartsName | null): any {
    return value as any;
}

