/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse200ArrowTypes,
    InlineResponse200ArrowTypesFromJSON,
    InlineResponse200ArrowTypesFromJSONTyped,
    InlineResponse200ArrowTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<InlineResponse200ArrowTypes>}
     * @memberof InlineResponse200
     */
    arrowTypes?: Array<InlineResponse200ArrowTypes>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'arrowTypes': !exists(json, 'arrow_types') ? undefined : ((json['arrow_types'] as Array<any>).map(InlineResponse200ArrowTypesFromJSON)),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'arrow_types': value.arrowTypes === undefined ? undefined : ((value.arrowTypes as Array<any>).map(InlineResponse200ArrowTypesToJSON)),
    };
}


