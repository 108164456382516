/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FamilyCrestColor,
    FamilyCrestColorFromJSON,
    FamilyCrestColorFromJSONTyped,
    FamilyCrestColorToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetResponseFamilyCrestColors
 */
export interface GetResponseFamilyCrestColors {
    /**
     * 
     * @type {Array<FamilyCrestColor>}
     * @memberof GetResponseFamilyCrestColors
     */
    colorData: Array<FamilyCrestColor>;
}

export function GetResponseFamilyCrestColorsFromJSON(json: any): GetResponseFamilyCrestColors {
    return GetResponseFamilyCrestColorsFromJSONTyped(json, false);
}

export function GetResponseFamilyCrestColorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponseFamilyCrestColors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colorData': ((json['color_data'] as Array<any>).map(FamilyCrestColorFromJSON)),
    };
}

export function GetResponseFamilyCrestColorsToJSON(value?: GetResponseFamilyCrestColors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color_data': ((value.colorData as Array<any>).map(FamilyCrestColorToJSON)),
    };
}


