/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartsProductOptions
 */
export interface PartsProductOptions {
    /**
     * 羽根を取得する時にセットされる。家紋添付可能フラグ。true=添付可能
     * @type {boolean}
     * @memberof PartsProductOptions
     */
    familyCrestEnable?: boolean;
    /**
     * 矢尺を取得する時にセットされる本数の数値。小計の計算時に利用する
     * @type {number}
     * @memberof PartsProductOptions
     */
    unit?: number;
    /**
     * シャフトを取得する時にセットされる。文字刻印可能フラグ。true=刻印可能
     * @type {boolean}
     * @memberof PartsProductOptions
     */
    carvingEnable?: boolean;
    /**
     * 3D表示する際にシステム側で利用するフォント名
     * @type {string}
     * @memberof PartsProductOptions
     */
    carvingFontName?: string;
    /**
     * 文字刻印の刻印方向:
     *   * `1` - 縦書き
     *   * `2` - 横書き
     * @type {number}
     * @memberof PartsProductOptions
     */
    carvingWritingType?: number;
    /**
     * シャフト表面の質感:
     *   * `gloss` - 光沢
     *   * `semigloss` - 半光沢
     *   * `matte` - マット
     *   * `metal` - メタリック
     *   * `carbon` - カーボン
     *   * `plating` - メッキ
     * @type {string}
     * @memberof PartsProductOptions
     */
    shaftSurfaceTexture?: string;
    /**
     * 筈の種類:
     *   * `tenkyu` - 天弓筈
     *   * `fukumi` - 含み筈
     * @type {string}
     * @memberof PartsProductOptions
     */
    nockKind?: string;
    /**
     * 矢尻の種類:
     *   * `short` - ショート
     *   * `long` - ロング
     * @type {string}
     * @memberof PartsProductOptions
     */
    arrowheadKind?: string;
    /**
     * マット加工可能かどうかのフラグ。true=マット加工可能、false=マット加工不可
     * @type {boolean}
     * @memberof PartsProductOptions
     */
    threadMatteEnable?: boolean;
    /**
     * * `normal` - 通常 * `hyoutan` - ひょうたん型 * `kazakiri` - 風切 * `none` - 羽根無し
     * @type {string}
     * @memberof PartsProductOptions
     */
    featherKind?: PartsProductOptionsFeatherKindEnum;
}

/**
* @export
* @enum {string}
*/
export enum PartsProductOptionsFeatherKindEnum {
    Normal = 'normal',
    Hyoutan = 'hyoutan',
    Kazakiri = 'kazakiri',
    None = 'none'
}

export function PartsProductOptionsFromJSON(json: any): PartsProductOptions {
    return PartsProductOptionsFromJSONTyped(json, false);
}

export function PartsProductOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsProductOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'familyCrestEnable': !exists(json, 'family_crest_enable') ? undefined : json['family_crest_enable'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'carvingEnable': !exists(json, 'carving_enable') ? undefined : json['carving_enable'],
        'carvingFontName': !exists(json, 'carving_font_name') ? undefined : json['carving_font_name'],
        'carvingWritingType': !exists(json, 'carving_writing_type') ? undefined : json['carving_writing_type'],
        'shaftSurfaceTexture': !exists(json, 'shaft_surface_texture') ? undefined : json['shaft_surface_texture'],
        'nockKind': !exists(json, 'nock_kind') ? undefined : json['nock_kind'],
        'arrowheadKind': !exists(json, 'arrowhead_kind') ? undefined : json['arrowhead_kind'],
        'threadMatteEnable': !exists(json, 'thread_matte_enable') ? undefined : json['thread_matte_enable'],
        'featherKind': !exists(json, 'feather_kind') ? undefined : json['feather_kind'],
    };
}

export function PartsProductOptionsToJSON(value?: PartsProductOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'family_crest_enable': value.familyCrestEnable,
        'unit': value.unit,
        'carving_enable': value.carvingEnable,
        'carving_font_name': value.carvingFontName,
        'carving_writing_type': value.carvingWritingType,
        'shaft_surface_texture': value.shaftSurfaceTexture,
        'nock_kind': value.nockKind,
        'arrowhead_kind': value.arrowheadKind,
        'thread_matte_enable': value.threadMatteEnable,
        'feather_kind': value.featherKind,
    };
}


