/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    PartsProduct,
    PartsProductFromJSON,
    PartsProductFromJSONTyped,
    PartsProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetResponseOrderParts
 */
export interface GetResponseOrderParts {
    /**
     * 
     * @type {Array<Category>}
     * @memberof GetResponseOrderParts
     */
    categoryData: Array<Category>;
    /**
     * 
     * @type {Array<PartsProduct>}
     * @memberof GetResponseOrderParts
     */
    partsData: Array<PartsProduct>;
}

export function GetResponseOrderPartsFromJSON(json: any): GetResponseOrderParts {
    return GetResponseOrderPartsFromJSONTyped(json, false);
}

export function GetResponseOrderPartsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponseOrderParts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryData': ((json['category_data'] as Array<any>).map(CategoryFromJSON)),
        'partsData': ((json['parts_data'] as Array<any>).map(PartsProductFromJSON)),
    };
}

export function GetResponseOrderPartsToJSON(value?: GetResponseOrderParts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_data': ((value.categoryData as Array<any>).map(CategoryToJSON)),
        'parts_data': ((value.partsData as Array<any>).map(PartsProductToJSON)),
    };
}


