/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectedItems,
    SelectedItemsFromJSON,
    SelectedItemsFromJSONTyped,
    SelectedItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * CartItemのID。カートの中身を変更する際に使用する
     * @type {number}
     * @memberof InlineObject
     */
    cartitemId: number;
    /**
     * 
     * @type {SelectedItems}
     * @memberof InlineObject
     */
    selectedItems: SelectedItems;
}

export function InlineObjectFromJSON(json: any): InlineObject {
    return InlineObjectFromJSONTyped(json, false);
}

export function InlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartitemId': json['cartitem_id'],
        'selectedItems': SelectedItemsFromJSON(json['selected_items']),
    };
}

export function InlineObjectToJSON(value?: InlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartitem_id': value.cartitemId,
        'selected_items': SelectedItemsToJSON(value.selectedItems),
    };
}


