/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectedItemsCarving,
    SelectedItemsCarvingFromJSON,
    SelectedItemsCarvingFromJSONTyped,
    SelectedItemsCarvingToJSON,
    SelectedItemsFamilycrest,
    SelectedItemsFamilycrestFromJSON,
    SelectedItemsFamilycrestFromJSONTyped,
    SelectedItemsFamilycrestToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectedItems
 */
export interface SelectedItems {
    /**
     * シャフトのID
     * @type {number}
     * @memberof SelectedItems
     */
    shaft?: number;
    /**
     * 羽根のID
     * @type {number}
     * @memberof SelectedItems
     */
    feather?: number;
    /**
     * 糸・和紙のID
     * @type {number}
     * @memberof SelectedItems
     */
    thread?: number;
    /**
     * 毛引きのID
     * @type {number}
     * @memberof SelectedItems
     */
    kebiki?: number;
    /**
     * 矢筈のID
     * @type {number}
     * @memberof SelectedItems
     */
    nock?: number;
    /**
     * 矢尻のID
     * @type {number}
     * @memberof SelectedItems
     */
    arrowhead?: number;
    /**
     * 矢尺のID
     * @type {number}
     * @memberof SelectedItems
     */
    arrowlength?: number;
    /**
     * 本数のID
     * @type {number}
     * @memberof SelectedItems
     */
    unit?: number;
    /**
     * インサートのID
     * @type {number}
     * @memberof SelectedItems
     */
    arrowinsert?: number;
    /**
     * 筈巻のID
     * @type {number}
     * @memberof SelectedItems
     */
    twine?: number;
    /**
     * ラメのID
     * @type {number}
     * @memberof SelectedItems
     */
    lame?: number;
    /**
     * 
     * @type {SelectedItemsCarving}
     * @memberof SelectedItems
     */
    carving?: SelectedItemsCarving;
    /**
     * 
     * @type {SelectedItemsFamilycrest}
     * @memberof SelectedItems
     */
    familycrest?: SelectedItemsFamilycrest;
    /**
     * 羽中文字のID
     * @type {number}
     * @memberof SelectedItems
     */
    featherinside?: number;
    /**
     * プチデコレーションのID
     * @type {number}
     * @memberof SelectedItems
     */
    decoration?: number;
    /**
     * ZERO流のID
     * @type {number}
     * @memberof SelectedItems
     */
    typezero?: number;
    /**
     * 糸・和紙のツヤ。true=ツヤあり、false=ツヤなし
     * @type {boolean}
     * @memberof SelectedItems
     */
    threadgloss?: boolean;
    /**
     * 羽根無し時の糸・和紙の場所 * `uraari` - 末矧あり * `uranashi` - 末矧なし
     * @type {string}
     * @memberof SelectedItems
     */
    threadPosition?: SelectedItemsThreadPositionEnum;
}

/**
* @export
* @enum {string}
*/
export enum SelectedItemsThreadPositionEnum {
    Uraari = 'uraari',
    Uranashi = 'uranashi'
}

export function SelectedItemsFromJSON(json: any): SelectedItems {
    return SelectedItemsFromJSONTyped(json, false);
}

export function SelectedItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shaft': !exists(json, 'shaft') ? undefined : json['shaft'],
        'feather': !exists(json, 'feather') ? undefined : json['feather'],
        'thread': !exists(json, 'thread') ? undefined : json['thread'],
        'kebiki': !exists(json, 'kebiki') ? undefined : json['kebiki'],
        'nock': !exists(json, 'nock') ? undefined : json['nock'],
        'arrowhead': !exists(json, 'arrowhead') ? undefined : json['arrowhead'],
        'arrowlength': !exists(json, 'arrowlength') ? undefined : json['arrowlength'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'arrowinsert': !exists(json, 'arrowinsert') ? undefined : json['arrowinsert'],
        'twine': !exists(json, 'twine') ? undefined : json['twine'],
        'lame': !exists(json, 'lame') ? undefined : json['lame'],
        'carving': !exists(json, 'carving') ? undefined : SelectedItemsCarvingFromJSON(json['carving']),
        'familycrest': !exists(json, 'familycrest') ? undefined : SelectedItemsFamilycrestFromJSON(json['familycrest']),
        'featherinside': !exists(json, 'featherinside') ? undefined : json['featherinside'],
        'decoration': !exists(json, 'decoration') ? undefined : json['decoration'],
        'typezero': !exists(json, 'typezero') ? undefined : json['typezero'],
        'threadgloss': !exists(json, 'threadgloss') ? undefined : json['threadgloss'],
        'threadPosition': !exists(json, 'thread_position') ? undefined : json['thread_position'],
    };
}

export function SelectedItemsToJSON(value?: SelectedItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shaft': value.shaft,
        'feather': value.feather,
        'thread': value.thread,
        'kebiki': value.kebiki,
        'nock': value.nock,
        'arrowhead': value.arrowhead,
        'arrowlength': value.arrowlength,
        'unit': value.unit,
        'arrowinsert': value.arrowinsert,
        'twine': value.twine,
        'lame': value.lame,
        'carving': SelectedItemsCarvingToJSON(value.carving),
        'familycrest': SelectedItemsFamilycrestToJSON(value.familycrest),
        'featherinside': value.featherinside,
        'decoration': value.decoration,
        'typezero': value.typezero,
        'threadgloss': value.threadgloss,
        'thread_position': value.threadPosition,
    };
}


