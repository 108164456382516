import { PartsProduct, FamilyCrestColor } from '@app/react/api';
import {
    MAKIWARA_THREAD_MODE_B,
    MAKIWARA_THREAD_MODE_C,
} from '@app/react/const/variables';

export const KINTEKI = 'kinteki';
export const ENTEKI = 'enteki';
export const MAKIWARA = 'makiwara';
export const FULL = 'full';
export const SEMI = 'semi';

export type {
    GetResponseOrderParts,
    GetResponseFamilyCrestColors,
    Category,
    SubCategory,
    PartsProduct,
    AddCart,
    SelectedItems,
    RecommendDesignItems,
    RecommendDesign,
    SelectedItemsCarving,
    SelectedItemsFamilycrest,
    FamilyCrestColor,
    PartsProductOptions,
    GetResponseCartItemData,
    RecommendDesignItemsFamilycrestColor,
} from '@app/react/api';

export {
    ArrowType as ArrowTypeAPI,
    OrderType as OrderTypeAPI,
    OrdermadeArrowPartsName,
    SelectedItemsThreadPositionEnum,
    RecommendDesignItemsThreadPositionEnum,
    PartsProductOptionsFeatherKindEnum,
    RecommendDesignFromJSON,
    PartsProductFromJSON,
} from '@app/react/api';

export type {
    RecommendDesignItems as CartItems,
} from '@app/react/api';

export type AsyncGeneralState = 'init' | 'request' | 'done' | 'error';

export interface DesignSet {
    id: number | null;
    baseId: number | null;
    shaft: PartsProduct | null;
    feather: PartsProduct | null;
    thread: PartsProduct | null;
    threadGloss: boolean;
    threadMode: MAKIWARA_THREAD_MODE;
    kebiki: PartsProduct | null;
    nock: PartsProduct | null;
    arrowhead: PartsProduct | null;
    arrowlengthAdjust: boolean | null;
    arrowlength: PartsProduct | null;
    unit: PartsProduct | null;
    arrowinsert: PartsProduct | null;
    twine: PartsProduct | null;
    lame: PartsProduct | null;
    carving: PartsProduct | null;
    carvingString: string | null;
    familycrest: PartsProduct | null;
    familycrestColor: FamilyCrestColor | null;
    typezero: PartsProduct | null;
    decoration: PartsProduct | null;
    featherinside: PartsProduct | null;
}

export type ArrowType = typeof KINTEKI | typeof ENTEKI | typeof MAKIWARA;
export type OrderType = typeof FULL | typeof SEMI;

export interface OrderPathParams {
    arrowType: ArrowType;
    orderType: OrderType;
}

export interface EditOrderPathParams {
    cartId: number;
    arrowType: ArrowType;
    orderType: OrderType;
}

/**
 *  シャフト表面の質感
 *
 * `gloss` - 光沢
 * `semigloss` - 半光沢
 * `matte` - マット
 * `metal` - メタリック
 * `carbon` - カーボン
 * `plating` - メッキ
 */
export type ShaftSurfaceTexture =
    | 'gloss'
    | 'semigloss'
    | 'matte'
    | 'metal'
    | 'carbon'
    | 'plating'
    | '';

/**
 * 筈の種類
 *
 * `tenkyu` - 天弓筈
 * `fukumi` - 含み筈
 */
export type NockKind = 'tenkyu' | 'fukumi';

/**
 * 矢尻の種類
 *
 * `short` - ショート
 * `long` - ロング
 */
export type ArrowheadKind = 'short' | 'long';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type MAKIWARA_THREAD_MODE =
    | typeof MAKIWARA_THREAD_MODE_B
    | typeof MAKIWARA_THREAD_MODE_C;

export type ProductWithCategory = Array<{
    categoryId: number;
    categoryName: string;
    subCategoryData: Array<{
        subCategoryId?: number;
        subCategoryName?: string;
        products: Array<PartsProduct>;
    }>;
}>;
