/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecommendDesign,
    RecommendDesignFromJSON,
    RecommendDesignFromJSONTyped,
    RecommendDesignToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetResponseCartItemData
 */
export interface GetResponseCartItemData {
    /**
     * 
     * @type {RecommendDesign}
     * @memberof GetResponseCartItemData
     */
    cartitemData: RecommendDesign;
}

export function GetResponseCartItemDataFromJSON(json: any): GetResponseCartItemData {
    return GetResponseCartItemDataFromJSONTyped(json, false);
}

export function GetResponseCartItemDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponseCartItemData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartitemData': RecommendDesignFromJSON(json['cartitem_data']),
    };
}

export function GetResponseCartItemDataToJSON(value?: GetResponseCartItemData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartitem_data': RecommendDesignToJSON(value.cartitemData),
    };
}


