/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 取得する矢の種別:
 *   * `kinteki` - 近的矢
 *   * `enteki` - 遠的矢
 *   * `makiwara` - 巻藁矢
 * @export
 * @enum {string}
 */
export enum ArrowType {
    Kinteki = 'kinteki',
    Enteki = 'enteki',
    Makiwara = 'makiwara'
}

export function ArrowTypeFromJSON(json: any): ArrowType {
    return ArrowTypeFromJSONTyped(json, false);
}

export function ArrowTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrowType {
    return json as ArrowType;
}

export function ArrowTypeToJSON(value?: ArrowType | null): any {
    return value as any;
}

