/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse2001OrderTypes,
    InlineResponse2001OrderTypesFromJSON,
    InlineResponse2001OrderTypesFromJSONTyped,
    InlineResponse2001OrderTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<InlineResponse2001OrderTypes>}
     * @memberof InlineResponse2001
     */
    orderTypes?: Array<InlineResponse2001OrderTypes>;
}

export function InlineResponse2001FromJSON(json: any): InlineResponse2001 {
    return InlineResponse2001FromJSONTyped(json, false);
}

export function InlineResponse2001FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2001 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderTypes': !exists(json, 'order_types') ? undefined : ((json['order_types'] as Array<any>).map(InlineResponse2001OrderTypesFromJSON)),
    };
}

export function InlineResponse2001ToJSON(value?: InlineResponse2001 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order_types': value.orderTypes === undefined ? undefined : ((value.orderTypes as Array<any>).map(InlineResponse2001OrderTypesToJSON)),
    };
}


