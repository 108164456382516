/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 家紋 色
 * @export
 * @interface RecommendDesignItemsFamilycrestColor
 */
export interface RecommendDesignItemsFamilycrestColor {
    /**
     * Product ID
     * @type {number}
     * @memberof RecommendDesignItemsFamilycrestColor
     */
    id: number;
    /**
     * サムネイル画像 PATH
     * @type {string}
     * @memberof RecommendDesignItemsFamilycrestColor
     */
    productImage?: string;
    /**
     * テクスチャ画像 PATH
     * @type {string}
     * @memberof RecommendDesignItemsFamilycrestColor
     */
    textureImage?: string;
}

export function RecommendDesignItemsFamilycrestColorFromJSON(json: any): RecommendDesignItemsFamilycrestColor {
    return RecommendDesignItemsFamilycrestColorFromJSONTyped(json, false);
}

export function RecommendDesignItemsFamilycrestColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendDesignItemsFamilycrestColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productImage': !exists(json, 'product_image') ? undefined : json['product_image'],
        'textureImage': !exists(json, 'texture_image') ? undefined : json['texture_image'],
    };
}

export function RecommendDesignItemsFamilycrestColorToJSON(value?: RecommendDesignItemsFamilycrestColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_image': value.productImage,
        'texture_image': value.textureImage,
    };
}


