/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FamilyCrestColor
 */
export interface FamilyCrestColor {
    /**
     * 
     * @type {number}
     * @memberof FamilyCrestColor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FamilyCrestColor
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FamilyCrestColor
     */
    enable: boolean;
    /**
     * 
     * @type {string}
     * @memberof FamilyCrestColor
     */
    productImage: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyCrestColor
     */
    textureImage: string;
}

export function FamilyCrestColorFromJSON(json: any): FamilyCrestColor {
    return FamilyCrestColorFromJSONTyped(json, false);
}

export function FamilyCrestColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FamilyCrestColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'enable': json['enable'],
        'productImage': json['product_image'],
        'textureImage': json['texture_image'],
    };
}

export function FamilyCrestColorToJSON(value?: FamilyCrestColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'enable': value.enable,
        'product_image': value.productImage,
        'texture_image': value.textureImage,
    };
}


