import {
    WebGLRenderer,
    Camera,
    PerspectiveCamera,
    HemisphereLight,
    AmbientLight,
    DirectionalLight,
    sRGBEncoding,
} from 'three';
import { OrbitControls } from '@app/react/uikit/OrbitControls';

export const createRenderer = (
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    ratio: number,
): WebGLRenderer => {
    const renderer = new WebGLRenderer({
        canvas,
        antialias: true,
        alpha: true,
    });
    renderer.setPixelRatio(ratio);
    renderer.setSize(width, height, false);
    renderer.outputEncoding = sRGBEncoding;

    return renderer;
};

type CreateLightRet = {
    hemisphereLight: HemisphereLight;
    ambientLight: AmbientLight;
    directionalLights: Array<DirectionalLight>;
};

export const createLight = (): CreateLightRet => {
    const hemiLight = new HemisphereLight(0xcccccc, 0xcccccc);
    hemiLight.position.set(0, 200, 0);

    const ambLight = new AmbientLight(0xaaaaaa, 1.0);

    const dirLightFront = new DirectionalLight(0xaaaaaa);
    dirLightFront.position.set(0, 0, 18);

    const dirLightBack = new DirectionalLight(0xaaaaaa);
    dirLightFront.position.set(0, 0, -18);

    return {
        hemisphereLight: hemiLight,
        ambientLight: ambLight,
        directionalLights: [dirLightFront, dirLightBack],
    };
};

export const createCamera = (
    width: number,
    height: number,
): PerspectiveCamera => {
    const camera = new PerspectiveCamera(30, width / height, 1, 2000);
    camera.position.set(0, 4, 100);

    return camera;
};

export const createOrbitControl = (
    camera: Camera,
    domElement: HTMLElement,
): OrbitControls => {
    const controls = new OrbitControls(camera, domElement);
    controls.minDistance = 3;
    controls.maxDistance = 100;
    controls.enableRotate = true;
    controls.enablePan = false;
    controls.target.set(0, 0, 0);
    controls.update();

    return controls;
};
