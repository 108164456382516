/* tslint:disable */
/* eslint-disable */
/**
 * 咲矢弓道具 オーダメイド SHOP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubCategory,
    SubCategoryFromJSON,
    SubCategoryFromJSONTyped,
    SubCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName: string;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof Category
     */
    subCategoryData: Array<SubCategory>;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['category_id'],
        'categoryName': json['category_name'],
        'subCategoryData': ((json['sub_category_data'] as Array<any>).map(SubCategoryFromJSON)),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_id': value.categoryId,
        'category_name': value.categoryName,
        'sub_category_data': ((value.subCategoryData as Array<any>).map(SubCategoryToJSON)),
    };
}


