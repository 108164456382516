export const ASSETS_PATH = '/html/template/default/assets';
export const CARVING_TEXT_COLOR_TEX_PATH = '/html/template/default/assets/models/silver.png';
export const NO_IMAGE_PRODUCT_PATH = '/html/upload/save_image/no_image_product.png';

export const ORDERMADE_ARROW_NAME = 'YUMI';

export const CARVING_WRITING_VERTICAL = 1; // from api.yml
export const CARVING_WRITING_HORIZONTAL = 2; // from api.yml
export const CARVING_VERTICAL = '（縦書き）';
export const CARVING_HORIZONTAL = '（横書き）';
export const CARVING_ATTENTION1 = '※全角のみ・8文字程度を推奨';
export const CARVING_ATTENTION2 = '※半角のみ・16文字程度を推奨';
export const CARVING_ATTENSION_DESCRIBE1 = '※3Dモデル上では8文字までしか表示されません。実際の仕上がりと異なる場合がございますのであらかじめご了承ください。';
export const CARVING_ATTENSION_DESCRIBE2 = '※3Dモデル上では16文字までしか表示されません。実際の仕上がりと異なる場合がございますのであらかじめご了承ください。';

export const ROUTE_PARTS = [
    'shaft',
    'hane',
    'washi',
    'kebiki',
    'hazu',
    'yajiri',
    'yashaku',
    'honsu',
    'insert',
    'hazumaki',
    'rame',
    'kokuin',
    'kamon',
    'typezero',
    'deco',
    'featherinside',
] as const;

export const ORDER_PARTS = [
    'shaft',
    'feather',
    'thread',
    'kebiki',
    'nock',
    'arrowhead',
    'arrowlength',
    'unit',
    'arrowinsert',
    'twine',
    'lame',
    'carving',
    'familycrest',
    'typezero',
    'decoration',
    'featherinside',
] as const;

export type OrderPartsType = typeof ORDER_PARTS[number];

export const ORDER_PARTS_NAME = {
    ja: {
        shaft: 'シャフト',
        feather: '羽根',
        thread: '糸・和紙',
        kebiki: '毛引き',
        nock: '筈',
        arrowhead: '矢尻',
        arrowlength: '矢尺',
        unit: '矢の本数',
        arrowinsert: 'インサート',
        twine: '筈巻加工',
        lame: 'ラメ加工',
        carving: '文字刻印',
        familycrest: '家紋印刷',
        typezero: 'ZERO流',
        decoration: 'プチデコレーション',
        featherinside: '羽中加工',
    },
};

export const MAKIWARA_THREAD_MODE_C = 'ari';
export const MAKIWARA_THREAD_MODE_B = 'nashi';

export const PARTS_PATH = '/:arrowType/:orderType/parts';

export const PARTS_PATH_EDIT = '/:cartId(\\d+)/:arrowType(kinteki|enteki|makiwara)/:orderType(full|semi)/parts';

export const KINTEKI_NAME = '近的矢';
export const ENTEKI_NAME = '遠的矢';
export const MAKIWARA_NAME = '巻藁矢';

export const SCROLL_PERSIST_KEY = [
    'cat_featherinside_opclc',
    'cat_shaft',
    'subcat_shaft',
    'cat_feather',
    'subcat_feather',
    'cat_twine',
    'subcat_twine',
    'cat_washi',
    'subcat_washi',
    'scroll_shaft',
    'scroll_feather',
    'scroll_washi',
    'scroll_kebiki',
    'scroll_yajiri',
    'scroll_unit',
    'scroll_insert',
    'scroll_hazu',
    'scroll_twine',
    'scroll_familycrest_list',
    'scroll_featherinside_opclc',
    'scroll_lame',
    'scroll_deco',
    'scroll_typezero',
];
