import {
    WebGLRenderer,
    HemisphereLight,
    AmbientLight,
    DirectionalLight,
    sRGBEncoding,
} from 'three';

export const createRenderer = (
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    ratio: number,
): WebGLRenderer => {
    const renderer = new WebGLRenderer({
        canvas,
        antialias: true,
        alpha: true,
    });
    renderer.setPixelRatio(ratio);
    renderer.setSize(width, height, false);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = sRGBEncoding;

    return renderer;
};

type CreateLightRet = {
    hemisphereLight: HemisphereLight;
    ambientLight: AmbientLight;
    directionalLights: Array<DirectionalLight>;
};

export const createLight = (): CreateLightRet => {
    const hemiLight = new HemisphereLight(0xcccccc, 0xcccccc);
    hemiLight.position.set(0, 200, 0);

    const ambLight = new AmbientLight(0xaaaaaa, 1.0);

    const dirLightFront = new DirectionalLight(0xaaaaaa);
    dirLightFront.position.set(0, 0, 18);

    const dirLightBack = new DirectionalLight(0xaaaaaa);
    dirLightFront.position.set(0, 0, -18);

    return {
        hemisphereLight: hemiLight,
        ambientLight: ambLight,
        directionalLights: [dirLightFront, dirLightBack],
    };
};
